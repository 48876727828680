import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  OnInit,
  Renderer2,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterOutlet } from '@angular/router';
import { Theme } from './common/services/theme/theme';
import { ThemeService } from './common/services/theme/theme.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  ContentComponent,
  EmailModalComponent,
  HeaderComponent,
  LoaderComponent,
  ModalNotificationComponent,
  SidenavComponent,
  ToasterComponent,
} from '@maersk-global/angular-shared-library';

import { NetworkService } from './common/services/network/network.service';
import { environment } from '../environments/environment';
import { AuthHelper, IIdToken, IAccessToken } from './helper/auth-helper';
import { CookieService } from 'ngx-cookie-service';

import '@maersk-global/mds-components-core/mc-button';
import '@maersk-global/mds-components-core/mc-input';
import '@maersk-global/mds-components-core/mc-icon';
import '@maersk-global/mds-components-core/mc-checkbox';
import '@maersk-global/mds-components-core/mc-pagination';
import '@maersk-global/mds-components-core/mc-select';
import '@maersk-global/mds-components-core/mc-option';
import '@maersk-global/mds-components-core/mc-select-native';
import '@maersk-global/mds-components-core/mc-tooltip';
import '@maersk-global/mds-components-core/mc-loading-indicator';
import '@maersk-global/mds-components-core/mc-modal';
import '@maersk-global/mds-components-core/mc-input-date';
import '@maersk-global/mds-components-core/mc-multi-select';
import '@maersk-global/mds-components-core/mc-multi-select-native';
import '@maersk-global/mds-components-core/mc-typeahead';
import '@maersk-global/mds-components-community/mc-c-tag-multiselect';
import '@maersk-global/mds-components-core/mc-modal';
import '@maersk-global/mds-components-core/mc-radio-group';
import '@maersk-global/mds-components-core/mc-radio';
import '@maersk-global/mds-components-core/mc-textarea';
import '@maersk-global/mds-components-core/mc-toast';
import '@maersk-global/mds-components-core/mc-notification';
import '@maersk-global/mds-components-core/mc-tab-bar';
import '@maersk-global/mds-components-core/mc-tab';
import '@maersk-global/mds-components-core/mc-icon';
import '@maersk-global/mds-components-community/mc-c-accordion-item';
import '@maersk-global/mds-components-core/mc-text-and-icon';
import '@maersk-global/mds-components-core-drawer';
import '@maersk-global/mds-components-core-button';

import { SessionTimeoutService } from './common/services/common/session-time-out-service';
import '@maersk-global/insightshub-embed-common/src/css/popup.css';
import { HelpFaqDrawerComponent } from './components/help-faq/help-faq-drawer/help-faq-drawer.component';
import { HelpAndFaqService } from './common/services/help-and-faq/help-and-faq.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    ReactiveFormsModule,
    FormsModule,
    HeaderComponent,
    SidenavComponent,
    ContentComponent,
    ModalNotificationComponent,
    ToasterComponent,
    EmailModalComponent,
    LoaderComponent,
    HelpFaqDrawerComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  title = 'fleet-dcrp-web';
  clickEvent: string = '';
  buttonLabel: string = 'Display Message';
  currentTheme: Theme = Theme.LIGHT; // Set the default theme to light
  statusCode: string = '';
  jwtIdPayload!: IIdToken;
  jwtAccessPayload: IAccessToken | undefined;
  authHelper: AuthHelper = new AuthHelper(this._cookieService);
  loggedIn: boolean = false;
  login_url: string = environment.login_url;
  isSiteUndermaintenance: boolean = environment.isSiteUndermaintenance;
  remainingTime: number = 0;
  isSideNavLoaded: boolean = true;
  constructor(
    private themeService: ThemeService,
    private renderer: Renderer2,
    private service: NetworkService,
    private _cookieService: CookieService,
    private router: Router,
    private _helpAnfFaq: HelpAndFaqService,
    private _sessionTimeOutService: SessionTimeoutService
  ) {
    this.themeService.setTheme(this.currentTheme, this.renderer);
    this.title = $localize`${this.title}`;
    if (
      this.router.url !== '/help-faq/user-guide' &&
      this.router.url !== '/help-faq/faq'
    ) {
      this._helpAnfFaq.showSideNavMenu();
    }
  }

  ngOnInit() {
    this.fogeRockSPAHandler();
    this._helpAnfFaq.routeListener$.subscribe((value) => {
      this.isSideNavLoaded = value;
    });
  }

  TestHttpCall() {
    const testurl: string = environment.url;
    this.service
      .httpCall(testurl)
      .subscribe((res) => (this.statusCode = res.responseStatus.statusCode));
  }

  fogeRockSPAHandler(): void {
    /***For Local Development put id token and access token Start
    const idTokenTest = '';
    const accessTokenTest = '';
    const refreshTokenTest = '';
    const testDate = Date.now() + 4 * 3600 * 1000; //4*3600*1000-- 4 hours in ms
    const idTok = localStorage.getItem(environment.iam_key + 'id_token');
    if (true) {
      localStorage.setItem(environment.iam_key + 'id_token', idTokenTest);
      localStorage.setItem(
        environment.iam_key + 'access_token',
        accessTokenTest
      );
      localStorage.setItem(
        environment.iam_key + 'refresh_token',
        refreshTokenTest
      );
      localStorage.setItem(
        environment.iam_key + 'expiry_date',
        testDate.toString()
      );
    }
    /***For Local Development put id token and access token End***/

    const loginStatus: string = sessionStorage.getItem('loginStatus') ?? '';
    //let loginStatus = '';

    if (loginStatus != 'LoggedIn') {
      try {
        const id_token: string =
          localStorage.getItem(environment.iam_key + 'id_token') ?? '';
        this.jwtIdPayload = this.authHelper.parseJwt<IIdToken>(id_token);
        const access_token: string =
          localStorage.getItem(environment.iam_key + 'access_token') ?? '';
        this.jwtAccessPayload =
          this.authHelper.parseJwt<IAccessToken>(access_token);
        this.handleScenarioBasedOnAccessType();
        this.loggedIn = true;
        sessionStorage.setItem('loginStatus', 'LoggedIn');
      } catch (ex) {
        //this will be set to false once FR is completely in place
        this.loggedIn = false;
      }
    } else {
      this.loggedIn = true;
    }

    window.addEventListener(
      'storage',
      (event) => {
        if (event.storageArea == localStorage) {
          this.triggerLogoutFromAllTab();
        }
      },
      false
    );
  }

  triggerLogoutFromAllTab(): void {
    if (localStorage.getItem(environment.iam_key + 'id_token') == undefined) {
      // DO LOGOUT
      this.authHelper.logout();
    }
  }

  handleScenarioBasedOnAccessType(): void {
    const accessType: string = this.jwtAccessPayload?.accessType ?? '';
    let userId = '';

    if (accessType === 'internal') {
      sessionStorage.setItem('isExternalLogged', 'false');
      sessionStorage.setItem(
        'username',
        this.jwtIdPayload.firstname + ' ' + this.jwtIdPayload.lastname
      );
      sessionStorage.setItem('email', this.jwtIdPayload.email);

      userId = this.jwtAccessPayload?.uniqueId ?? '';
      sessionStorage.setItem('userId', userId);
    } else if (accessType === 'external') {
      sessionStorage.setItem('isExternalLogged', 'true');
      sessionStorage.setItem('ExternalUserName', this.jwtIdPayload.username);

      userId = this.jwtAccessPayload?.sub ?? '';
      sessionStorage.setItem('userId', userId);
    }
  }

  //redirect to login page
  redirectToLogin() {
    this.router.navigateByUrl(this.login_url);
  }
}
